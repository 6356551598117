import Keycloak from "keycloak-js";
import config from "../../config";
import User from "../../models/user";
import { getLocalStorage, setLocalStorage } from "../../utils/commonHelper";
import { BaseAuth } from "./base-auth";

export class KeyCloakAuth extends BaseAuth {
    /**
     * Constructor
     */
    constructor() {
        super();
        this.keyCloak = new Keycloak({
            "clientId": config.env("REACT_APP_CLIENT_ID"),
            "postLogoutRedirectUri": config.env("REACT_APP_REDIRECT_URL"),
            "realm": "r",
            "url": config.env("REACT_APP_AUTH_URL")
        });
    }

    /**
     * Login using key cloak
     * @returns {object} User
     */
    async login() {
        try {
            // This will start the login process by redirecting (window.location.assign())
            // the user to McKinsey ID login broker
            const loginResult = await this.keyCloak.login();
            setLocalStorage("user", loginResult, true);

            // When keyCloak.login() resolves, you can safely display the app
            return loginResult;
        } catch (err) {
            // Something when wrong in the login process, display an error message
            return null;
        }
    }

    /**
     * Logout using key cloak
     */
    async logout() {
        super.logout();
        setTimeout(async () => {
            await this.keyCloak.logout();
        }, 100);
    }

    /**
     * Check if user is authenticated
     * @param {Function} callBack - Callback function
     */
    async isAuthed(callBack) {
        // This will check if the user is already login (if you have valid tokens stored locally)
        if (!this.checkTokenValid()) {
            const isAuthed = await this.keyCloak.init({
                "flow": "implicit",
                "onLoad": "login-required"
            });
            const token = this.keyCloak.idToken;
            if (isAuthed && token !== null) {
                this.setHeader({
                    "authorization": `Bearer ${token}`
                });
            }
            callBack(isAuthed);
        } else {
            callBack(true);
        }
    }

    /**
     * Get user from mid service if not in local storage.
     * @param {boolean} forceUpdate - True, if user info should be fetched again from MID
     * @returns {User} = User
     */
    async getUser(forceUpdate = true) {
        let user;
        const userObject = getLocalStorage("user", true);
        if (userObject && !forceUpdate) {
            user = new User(userObject);
        } else {
            user = await this.login();
        }

        return user;
    }

    /**
     * Treat groups that starts with tenant- as tenant and others as user role
     * @param {Array} groups - Groups from MID login
     * @returns {object} - Roles and tenants
     */
    getTenantAndRolesFromGroups(groups) {
        const tenants = [];
        const roles = [];
        // Treat groups that starts with tenant- as tenant and others as user role
        groups.forEach((group) => {
            group.startsWith("/tenant-") ? 
                tenants.push(group.replace("/tenant-", "")) : roles.push(group.replace("/", ""));
        });

        return { roles, tenants };
    }
}

import { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const RoutesComponent = lazy(() => import("../components/RoutesComponent/RoutesComponent"));

Object.defineProperty(Object.prototype, "isBlank", {
    /**
     * This is used to get value of isBlank property
     * @returns {boolean} - true/false
     */
    "get": function () {
        return Object.keys(this).length === 0;
    }
});

Object.defineProperty(Array.prototype, "isLastElement", {
    /**
     * This is used to tell if an item is last in the list.
     * @param {string} item - Item that we want to compare if it is the last or not
     * @returns {boolean} - true/false
     */
    "value": function (item) {
        const lastElement = this[this.length - 1];

        return lastElement === item;
    }
});

/**
 * App component with loader and toasts
 * @returns {Element} - App component
 */
const App = () => {
    const { i18n } = useTranslation();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (i18n && !ready) {
            if (i18n.hasResourceBundle(i18n.language || "en", "enums")) {
                setReady(true);
            } else {
                i18n.store.on("added", function (lng, ns) {
                    if (!ready) {
                        if (ns === "enums") {
                            setReady(true);
                        }
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n]);

    /**
     * Removes main loader on load of application
     */
    useEffect(() => {
        /**
         * Remove main application loader
         */
        const removeMainLoader = () => {
            const loaderContainer = document.querySelector(".loader-container");
            if (loaderContainer) {
                loaderContainer.remove();
            }
        };

        // Remove main loader when component loads
        if (ready) {
            removeMainLoader();
        }
    }, [ready]);

    if (!ready) {
        return null;
    } else {
        return (
            <div className={"app-container"} data-testid={"app-container"}>
                <ToastContainer
                    position={"bottom-right"}
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss={true}
                    draggable={true}
                    pauseOnHover={true}
                    theme={"dark"}
                    icon={false}
                />
                <Suspense>
                    <RoutesComponent />
                </Suspense>
            </div>
        );
    }
};

export default App;

import { matchPath } from "react-router-dom";
import axios from "./axios";
import { serviceUrls } from "./serviceUrls";

/**
 * Get tenant configuration from API
 * @param {object} config - API config
 * @returns {Promise} - API Promise
 */
export const getTenantConfig = (config = { "handlerEnabled": true }) => {
    return axios.get(serviceUrls("configuration"), config).catch({});
};

/**
 * Get tenant info from API
 * @param {object} config - API config
 * @returns {Promise} - API Promise
 */
export const getTenantInfo = (config = { "handlerEnabled": true }) => {
    return axios.get(serviceUrls("system.tenant"), config).catch({});
};

const RESERVED_TENANT_KEYWORDS = ["maintenance", "super-admin-dashboard", "select-tenant", "login", "auth"];

/**
 * Get tenant_id from url
 * @returns {string} tenant_id - Tenant ID
 */
export const getTenantFromUrl = () => {
    const match = matchPath(
        {
            "caseSensitive": false,
            "end": false,
            "path": "/:tenant_id/"
        },
        window.location.pathname
    );
    if (match?.params?.tenant_id && !RESERVED_TENANT_KEYWORDS.includes(match.params.tenant_id)) {
        return match.params.tenant_id;
    }

    return null;
};

import { capitalizeFirst } from "../utils/StringFormatter/StringFormatter";

export default class User {
    email;
    firstName;
    lastName;
    roles = []; // viewer, admin, super_admin
    tenants = [];
    selectedTenant;

    /**
     * Set user properties
     * @param {object} input - User Input
     */
    constructor(input) {
        this.email = input.email ? input.email : "";
        this.firstName = input.firstName ? input.firstName : "";
        this.lastName = input.lastName ? input.lastName : "";
        this.tenants = input.tenants ? input.tenants : [];
        this.roles = input.roles ? input.roles : [];

        // Sort tenants alphabetically, to maintain consistency
        this.tenants.sort();
    }

    /**
     * Check if user is super admin
     * @returns {boolean} true if super admin
     */
    isSuperAdmin() {
        return this.roles.includes("super_admin");
    }

    /**
     * Check if user is  admin
     * @returns {boolean} - True if admin or super_admin
     */
    isAdmin() {
        return this.roles.includes("super_admin") || this.roles.includes("admin");
    }

    /**
     * Format tenant name and return
     * @param {string} tenant - Tenant id
     * @returns {string} - Tenant's display name
     */
    getTenantDisplayName(tenant) {
        return capitalizeFirst(tenant);
    }

    /**
     * Format selected tenant return
     * @returns {string} - Selected tenant's display name
     */
    getSelectedTenant() {
        return this.selectedTenant !== null ? this.selectedTenant : "";
    }

    /**
     * Set selected tenant if it exists in allowed tenants and not already selected
     * @param {string} tenant - Tenant that needs to be set
     */
    setTenant(tenant) {
        if (this.tenants.includes(tenant) || !tenant) {
            this.selectedTenant = tenant;
        }
    }

    /**
     * Format selected tenant's display name and return
     * @returns {string} - Selected tenant's display name
     */
    getSelectedTenantName() {
        return this.getTenantDisplayName(this.getSelectedTenant());
    }

    /**
     * Replace tenant name with new tenant name in the url
     * @param {string} oldTenant - Old tenant
     * @param {string} newTenant - New tenant
     * @returns {string} - Url with new tenant
     */
    replaceTenantInUrl(oldTenant, newTenant) {
        return window.location.pathname.replace(oldTenant, newTenant);
    }

    /**
     * Replace placeholder :tenant_id with selected tenant name
     * @param {string} url - Url
     * @returns {string} - Url with tenant name
     */
    replacePlaceholderWithTenant(url) {
        return url.replace(":tenant_id", this.selectedTenant);
    }
}

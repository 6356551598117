import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import App from "./App/App";
// Custom Fonts
import "./assets/fonts/TheinhardtPan/TheinhardtPan-Light.css";
import "./assets/fonts/TheinhardtPan/TheinhardtPan-Medium.css";
import "./assets/fonts/TheinhardtPan/TheinhardtPan-Regular.css";
import Loader from "./components/Loaders/Loader/Loader";
import config from "./config";
import "./i18n";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

const agGridKey = config.env("REACT_APP_AG_GRID_KEY", "");

if (agGridKey === "") {
    throw new Error("Please provide AG Grid key.");
}

LicenseManager.setLicenseKey(agGridKey);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    // TODO:- Add back strict mode after resolving issues with Overview screen and Rapid Diagnostics
    // <StrictMode>
    <Suspense fallback={<Loader />}>
        <App />
    </Suspense>
    // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

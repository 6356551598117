/**
 * Delete all Databases
 */
export const deleteAllDatabases = async () => {
    const dbs = await indexedDB.databases();
    for (const db of dbs) {
        indexedDB.deleteDatabase(db.name);
    }
};

/**
 * Get All Rows
 * @param {string} dbName - Database Name
 * @param {string} objectStoreName - Object Store Name
 * @returns {(Array<object>|boolean)} false if objectStore is not present else row data
 */
export const getAllRows = (dbName, objectStoreName) =>
    new Promise((resolve, reject) => {
        let db;
        const request = indexedDB.open(dbName);

        /**
         * Callback on successfully opening db
         * @param {Event} event - Event
         */
        request.onsuccess = (event) => {
            db = event.target.result;
            const objectStores = db.objectStoreNames;
            if (objectStores.contains(objectStoreName)) {
                let data = [];
                let relevantStores = 0;
                let gotRelevantData = 0;
                // Loop through all object stores and check matching names, to get data from chunks for
                // similar type of data
                // Chunks are stored with same name with chunk number appended at end
                Array.from(objectStores).forEach((storeName) => {
                    if (storeName.includes(objectStoreName)) {
                        relevantStores++;
                        const readTransactionRequest = db.transaction(storeName).objectStore(storeName).getAll();

                        /**
                         * Callback on successful read transaction
                         * @param {Event} event - Event
                         */
                        readTransactionRequest.onsuccess = (event) => {
                            gotRelevantData++;
                            data = data.concat(event.target.result);

                            if (gotRelevantData === relevantStores) {
                                data = data.length ? data : null;

                                db?.close();
                                resolve(data);
                            }
                        };
                    }
                });
            } else {
                db?.close();
                reject({ "dbVersion": db.version, "status": false });
            }
        };

        /**
         * Callback on error while opening db
         * @param {Event} event - Event
         */
        request.onerror = (event) => {
            db?.close();
            reject(event.target.errorCode);
        };
    });

/**
 * Add Data to IndexedDB
 * @param {string} dbName - Database Name
 * @param {number} dbVersion - Database Version
 * @param {string} objectStoreName - Object Store Name
 * @param {Array<object>} data - Array of Objects
 * @returns {(Promise.resolve|Promise.reject)} Promise callbacks
 */
export const addData = (dbName, dbVersion, objectStoreName, data) =>
    new Promise((resolve, reject) => {
        let db;

        const request = indexedDB.open(dbName, dbVersion + 1);

        /**
         * Callback if upgrade needed while opening db
         * @param {Event} event - Event
         */
        request.onupgradeneeded = async (event) => {
            db = event.target.result;
            await db.createObjectStore(objectStoreName, { "keyPath": "id" });
        };

        /**
         * Callback on successfully opening db
         * @param {Event} event - Event
         */
        request.onsuccess = async (event) => {
            db = event.target.result;
            const tx = await db.transaction(objectStoreName, "readwrite");
            const store = await tx.objectStore(objectStoreName);
            for (const record of data) {
                await store.add(record);
            }
            await db?.close();
            resolve();
        };

        /**
         * Callback on error while opening db
         * @param {Event} event - Event
         */
        request.onerror = (event) => {
            db?.close();
            reject(event.target.errorCode);
        };
    });

/**
 * Remove Object Store
 * @param {string} dbName - Database Name
 * @param {Array<string>} objectStores - Array of object store names
 */
export const removeObjectStores = (dbName, objectStores) => {
    return new Promise((resolve, reject) => {
        let db;
        const objectStoresToRemove = [];
        const request = indexedDB.open(dbName);

        /**
         * Callback on successfully opening db
         * @param {Event} event - Event
         */
        request.onsuccess = (event) => {
            db = event.target.result;

            for (const store of objectStores) {
                const objectStores = db.objectStoreNames;
                if (objectStores.contains(store)) {
                    // Loop through all object stores and check matching names, to delete data from chunks for
                    // similar type of data
                    // Chunks are stored with same name with chunk number appended at end
                    Array.from(objectStores).forEach((storeName) => {
                        if (storeName.includes(store)) {
                            objectStoresToRemove.push(storeName);
                        }
                    });
                }
            }

            if (objectStoresToRemove) {
                const version = db.version;
                db?.close();
                const upgradeRequest = indexedDB.open(dbName, version + 1);

                /**
                 * Callback if upgrade needed while opening db
                 * @param {Event} event - Event
                 */
                upgradeRequest.onupgradeneeded = (event) => {
                    db = event.target.result;

                    for (const store of objectStoresToRemove) {
                        db.deleteObjectStore(store);
                    }

                    db?.close();
                    resolve();
                };

                /**
                 * Callback on successfully upgrading db version
                 * @param {Event} event - Event
                 */
                upgradeRequest.onsuccess = (event) => {
                    db = event.target.result;

                    db?.close();
                    resolve();
                };
            } else {
                db?.close();
                resolve();
            }
        };

        /**
         * Callback on error while opening db
         * @param {Event} event - Event
         */
        request.onerror = (event) => {
            db?.close();
            reject(event);
        };
    });
};

import config from "../../config";
import User from "../../models/user";
import { getLocalStorage, setLocalStorage } from "../../utils/commonHelper";
import { BaseAuth } from "./base-auth";

export class MidAuth extends BaseAuth {
    /**
     * Constructor
     */
    constructor() {
        super();
        try {
            const { Mid } = require("@mid/sdk");

            this.mid = new Mid({
                // Remove hash code after login
                /**
                 * MID Landing function
                 */
                "landingFn": this.handleRedirect,
                "loginInfo": {
                    "appId": config.env("REACT_APP_CLIENT_ID"),
                    "authDriver": "mid",
                    "oidcConfigUrl": config.env("REACT_APP_OIDC_CONFIG_URL")
                },
                "logoutRedirectUrl": `${window.location.origin}${config.env("REACT_APP_LOGOUT_URL")}`,
                "redirectUrl": `${window.location.origin}${config.env("REACT_APP_REDIRECT_URL")}`
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Mid sdk not found");
        }
    }

    /**
     * Login using mid
     * @returns {object} User
     */
    async login() {
        try {
            // This will start the login process by redirecting (window.location.assign())
            // the user to McKinsey ID login broker
            const loginResult = await this.mid.login();
            const { tenants, roles } = this.getTenantAndRolesFromGroups(loginResult.idInfo?.groups);
            const user = new User({
                "email": loginResult.idInfo?.email,
                "firstName": loginResult.idInfo?.given_name,
                "lastName": loginResult.idInfo?.family_name,
                roles,
                tenants
            });
            setLocalStorage("user", user, true);

            // When mid.login() resolves, you can safely display the app
            return user;
        } catch (err) {
            // Something when wrong in the login process, display an error message
            return null;
        }
    }

    /**
     * Logout using mid
     */
    async logout() {
        super.logout();
        await this.mid.logout();
    }

    /**
     * Check if user is authenticated
     * @param {Function} callBack - Callback method to be called after auth
     */
    isAuthed(callBack) {
        // This will check if the user is already login (if you have valid tokens stored locally)
        if (!this.checkTokenValid()) {
            this.mid.isAuthed().then((isAuthed) => {
                const token = this.mid.idToken();
                if (isAuthed && token !== null) {
                    this.setHeader({
                        "authorization": `Bearer ${token}`
                    });
                }
                callBack(isAuthed);
            });
        } else {
            callBack(true);
        }
    }

    /**
     * Get user from mid service if not in local storage.
     * @param {boolean} forceUpdate - True, if user info should be fetched again from MID
     * @returns {User} = User
     */
    async getUser(forceUpdate = true) {
        let user;
        const userObject = getLocalStorage("user", true);
        if (userObject && !forceUpdate) {
            user = new User(userObject);
        } else {
            user = await this.login();
        }

        return user;
    }

    /**
     * Treat groups that starts with tenant- as tenant and others as user role
     * @param {Array} groups - Groups from MID login
     * @returns {object} - Roles and tenants
     */
    getTenantAndRolesFromGroups(groups) {
        const tenants = [];
        const roles = [];
        // Treat groups that starts with tenant- as tenant and others as user role
        groups.forEach((group) => {
            group.startsWith("tenant-") ? tenants.push(group.replace("tenant-", "")) : roles.push(group);
        });

        return { roles, tenants };
    }
}

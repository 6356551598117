/**
 * Convert underscore casing to title case
 * @param {string} underscoreString - String with underscore casing
 * @returns {string} String with title casing
 */
export const underscoreToTitle = (underscoreString) => {
    let title = "";
    try {
        const arr = underscoreString.split("_");
        arr.forEach((element) => {
            const firstChar = element.charAt(0).toUpperCase();
            title = title.concat(`${firstChar + element.slice(1)} `);
        });
    } catch (e) {
        title = underscoreString;
    }

    return title;
};

/**
 * Convert tile to underscore casing
 * @param {string} title - String with title casing
 * @returns {string} String with underscore casing
 */
export const titleToUnderScore = (title) => {
    return title.trim().replace(" ", "_").toLowerCase();
};

/**
 * Get file separator from string file path
 * @param {string} fileName - File name
 * @returns {string} - Separator \\ or /
 */
export const getFileSeparator = (fileName) => {
    const separatorRegex = /\\/g;

    return separatorRegex.test(fileName) ? "\\" : "/";
};

/**
 * Capitalize first letter of string
 * @param {string} str - String to be converted
 * @returns {*} String with first letter capital, if valid value provided
 */
export const capitalizeFirst = (str) => {
    // checks for null, undefined and empty string
    if (!str) {
        return;
    }

    return str.match("^[a-z]") ? str.charAt(0).toUpperCase() + str.substring(1) : str;
};

/**
 * Sort array object
 * @param {Array} data - Array
 * @param {string} fieldToSort - Field to be used for sorting in object
 * @returns {Array} Sorted array based on fieldToSort
 */
export const sortArrayOfObject = (data, fieldToSort) => {
    data.sort((object1, object2) => {
        const field1 = object1[fieldToSort] ? object1[fieldToSort].toUpperCase() : "Z";
        const field2 = object2[fieldToSort] ? object2[fieldToSort].toUpperCase() : "Z";
        if (field1 < field2) {
            return -1;
        }

        if (field1 > field2) {
            return 1;
        }

        // names must be equal
        return 0;
    });

    return data;
};

/**
 * Creates random string 5 characters
 * @returns {string} - Random 5 letter string
 */
export const createRandomString = () => {
    return Math.random().toString(36).slice(2, 7);
};

import { toast } from "react-toastify";
import config from "../config";
import i18n from "../i18n";

/**
 * This method calculate the size of local storage and only save the data if it has
 * sufficient memory available. We will use this function internally when setting local storage.
 * @returns {number} - size of local storage in bytes
 */
const getLocalStorageSize = () => {
    try {
        let totalSize = 0;
        for (const key in localStorage) {
            if (key in localStorage) {
                totalSize += encodeURIComponent(key)?.length ?? 0; // add key size
                totalSize += localStorage.getItem(key)?.length ?? 0; // add value size
            }
        }

        return totalSize;
    } catch (e) {
        throw new Error("Something went wrong in calculating local storage space", e);
    }
};

/**
 * This method will store data in local storage only if memory in available in local storage.
 * Otherwise it will throw an error.
 * @param {string} key - key
 * @param {any} value - value
 * @param {boolean} shouldStringify - shouldStringify
 */
export const setLocalStorage = (key, value, shouldStringify = false) => {
    try {
        let currentItemSize = 0;
        const valueToStore = shouldStringify ? JSON.stringify(value) : value;
        currentItemSize += encodeURIComponent(key).length;
        currentItemSize += String(valueToStore).length;

        if (getLocalStorageSize() + currentItemSize < 5000000) {
            localStorage.setItem(key, valueToStore);
        }
    } catch (e) {
        // For developers, console.log 'e' to debug issue
        toast.error("Could not store this data");
    }
};

/**
 * To get value from local storage either parsed or as it is
 * @param {string} key - key
 * @param {boolean} shouldParse - shouldParse
 * @param {*} defaultValue - generally null otherwise whatever user has provided
 * @returns {string | object} - return value
 */
export const getLocalStorage = (key, shouldParse = false, defaultValue = null) => {
    const localStoredValue = localStorage.getItem(key);
    if (localStoredValue) {
        return shouldParse ? JSON.parse(localStoredValue) : localStoredValue;
    }

    return defaultValue;
};

/**
 * To delete value from local storage
 * @param {string} key - key
 */
export const deleteLocalStorage = (key) => {
    localStorage.removeItem(key);
};

/**
 * To set value in session storage
 * @param {string} key - key
 * @param {any} value - value
 * @param {boolean} shouldStringify - shouldStringify
 */
export const setSessionStorage = (key, value, shouldStringify = false) => {
    sessionStorage.setItem(key, shouldStringify ? JSON.stringify(value) : value);
};

/**
 * To get value from session storage either parsed or as it is
 * @param {string} key - key
 * @param {boolean} shouldParse - shouldParse
 * @returns {string | object} - return value
 */
export const getSessionStorage = (key, shouldParse = false) => {
    if (shouldParse) {
        try {
            return JSON.parse(sessionStorage.getItem(key));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn("Failed to fetch from session storage", key);
        }
    } else {
        return sessionStorage.getItem(key);
    }

    return null;
};

/**
 * To check if any value in object is null
 * @param {object} obj - Object to verify
 * @returns {boolean} - return value
 */
export const isObjectValuesPresent = (obj) => {
    return Object.keys(obj).length > 0 && Object.values(obj).every((item) => item !== null);
};

/**
 * To update value of local storage and sync with current values
 * @param {string} key - local storage key
 * @param {string} intermediateKey - This is the inner level 1 key
 * @param {any} value - Value to save
 */
export const updateLocalStorageValue = (key, intermediateKey, value) => {
    const updatedObj = {};
    updatedObj[intermediateKey] = value;
    const currentLocalFileDetails = getLocalStorage(key, true) || {};
    setLocalStorage(key, { ...currentLocalFileDetails, ...updatedObj }, true);
};

/**
 * To generate a unique key
 * @param  {Array} args - array of keys to combine
 * @returns {string} - Unique string generated from all arguments
 */
export const getUniqueKey = (...args) =>
    args
        .reduce((finalValue, currentValue) => `${currentValue ? `${currentValue}_` : ""}${finalValue}`, "")
        .replace(/.$/, "");

/**
 * Columns Flattened Data Model
 * @param {object} dataModel - Data Model
 * @param {Array} tables - Tables List
 * @returns {object} Flattened Data Model
 */
export const getColumnsFlattenDataModel = (dataModel, tables = null) => {
    let flattenedColumnsDataModel = {};
    const columns = tables ? tables : Object.keys(dataModel?.columns || {});
    for (const table of columns) {
        if (dataModel?.columns && table in dataModel.columns) {
            flattenedColumnsDataModel = {
                ...flattenedColumnsDataModel,
                ...dataModel.columns[table]
            };
        }
    }

    return flattenedColumnsDataModel;
};

/**
 * Convert file/folder size to Bytes
 * @param {string} sizeStr - size string in form ("123 KB" or "12.4 MB")
 * @returns {number} - Memory size in Bytes
 */
export const convertToByte = (sizeStr) => {
    const sizeStrArray = sizeStr.split(" ");
    let memorySize = Number(sizeStrArray[0]);
    const memoryUnit = sizeStrArray[1];
    if (memoryUnit === "KB") {
        memorySize *= 1000;
    } else if (memoryUnit === "MB") {
        memorySize *= 1000000;
    } else if (memoryUnit === "GB") {
        memorySize *= 1000000000;
    }

    return memorySize;
};

/**
 * Download Blob Object as File
 * @param {Blob} blob - Blob Object
 * @param {string} fileName - File Name
 */
export const downloadBlobAsFile = (blob, fileName) => {
    if (blob.size) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName || "download");
        link.click();
        link.remove();
    }
};

/**
 * This function takes an array as input and provides an object with minimum and maximum values.
 * No issues even if value is string, null, undefined in array.
 * @param {Array} dataArray - Array containing numeric values to find min and max values
 * @param {number | undefined} maxValue - User provided max value limit
 * @returns {object} minMaxObject containing minimum and maximum values
 */
export const getMinMaxValues = (dataArray, maxValue) => {
    const minMaxObject = {
        "max": 0,
        "min": 0
    };

    dataArray.forEach((item) => {
        if (Number(item) > minMaxObject.max) {
            minMaxObject.max = Number(item);
        } else if (Number(item) < minMaxObject.min) {
            minMaxObject.min = Number(item);
        }
    });

    if (maxValue && minMaxObject.max > maxValue) {
        minMaxObject.max = maxValue;
    }

    return minMaxObject;
};

/**
 * To get period value
 * @param {string} key - object key whose value is required
 * @param {number} count - provide more than 1 to get its plural value with 's' in the end.
 * @param {boolean} allLower - to convert the value to all lower case
 * @returns {string} - current period
 */
export const getPeriodNotations = (key, count = 1, allLower = false) => {
    const period = i18n.t(`periodNotations.${key}`, { count });

    return allLower ? period.toLowerCase() : period;
};

/**
 * Should return boolean value telling if a cell should expand inner table or not
 * @param {object} param - ag grid object
 * @returns {boolean} - Inner table should expand or not
 */
export const shouldNotExpand = (param) => {
    const currentTarget = param.event.target;
    const parentElement = currentTarget.parentElement;

    return (
        (currentTarget && Array.from(currentTarget.classList).includes("no-expand")) ||
        (parentElement && Array.from(parentElement.classList).includes("no-expand"))
    );
};

/**
 * max value on last bar
 * @param {object} params - chart params
 * @returns {string} - max value on last bar
 */
export const showMaxValueString = (params) => {
    if (String(params.x).includes(">")) {
        return `${i18n.t("overflow_max_value_info", {
            "maxValue": params?.max_value,
            "overflowValue": params.x.split(">")[1]
        })}<br/>`;
    }

    return "";
};

/**
 * Provide drop down options with specific key at top.
 * @param {object} enumObj - Enum values
 * @param {string} enumName - drop down data key in enums json
 * @param {string} key - key we want to move on top
 * @returns {Array} - drop down options
 */
export const enumsOptionsGenerator = (enumObj, enumName, key = "auto") => {
    const autoOptions = [];
    const autoLimOptions = [];
    const otherOptions = [];

    Object.keys(enumObj).forEach((elem) => {
        const enumValue = enumObj[elem];

        if (enumValue === key) {
            autoOptions.push({
                "label": i18n.t(`${enumName}_${enumValue}`),
                "value": elem
            });
        } else if (enumValue === "auto_lim_po_no_threshold_else_normal_sampling") {
            autoLimOptions.push({
                "label": i18n.t(`${enumName}_${enumValue}`),
                "value": elem
            });
        } else {
            otherOptions.push({
                "label": i18n.t(`${enumName}_${enumValue}`),
                "value": elem
            });
        }
    });

    // Combine the arrays, ensuring "auto" is first and "auto_lim_po_no_threshold_else_normal_sampling" is second
    return [...autoOptions, ...autoLimOptions, ...otherOptions];
};

/**
 * Is a percentage/percentile field
 * @param {string} field - Field Name
 * @param {object} columnsDataModel -
 * @returns {boolean} true if column is a percentage column else false
 */
export const isPercentageOrPercentile = (field, columnsDataModel) => {
    return ["percentage", "percentile"].includes(columnsDataModel?.[field]?.special_type);
};

/**
 * Redirect to survey page for generating template
 * @param {string} tenantId - current tenant id
 * @param {string} email - current user email
 * @param {number} lastSurveyJobId - Last survey export job id to keep track of new survey job
 * @param {string} surveyResponseId - previous filled survey id
 */
export const redirectToSurveyPage = (tenantId, email, lastSurveyJobId = null, surveyResponseId = null) => {
    let generateSurveyLink = config.env("REACT_APP_SURVEY_LINK");
    if (!lastSurveyJobId) {
        lastSurveyJobId = 0;
    }
    // eslint-disable-next-line max-len
    generateSurveyLink += `?tenant_id=${tenantId}&base_url=${encodeURIComponent(
        window.location.origin
    )}&user_email=${encodeURIComponent(email)}&last_survey_job_id=${lastSurveyJobId}`;
    if (surveyResponseId) {
        generateSurveyLink += `&${surveyResponseId}`;
    }
    window.open(generateSurveyLink, "_blank");
};

/**
 * Generate drop down options for config values
 * @param {object} enumObject - contain enums for mapping
 * @param {object} columnMappingValue - contain key name from column mapping
 * @param {string} variableName - Current field variable name
 * @returns {Array} - array of options to show in drop down
 */
export const getConfigValuesDropDownOptions = (enumObject, columnMappingValue, variableName) => {
    const options = [];
    for (const key in enumObject) {
        options.push({
            "isDisabled": variableName === "ts_aggregation_simulation" && enumObject[key] !== "D",
            "label": i18n.t(`enums:${columnMappingValue}_${enumObject[key]}`),
            "value": enumObject[key]
        });
    }

    return options;
};

/**
 * This method will calculate doh potential value that we will use for showing in table and further calculations.
 * @param {object} dataObj - Current item in the table
 * @returns {number} - calculated value
 */
export const calculateDohPotential = (dataObj) => {
    const calculatedValue = (dataObj.actual_avg_days - dataObj.high_level_doh_target) / dataObj.actual_avg_days;

    return isNaN(calculatedValue) ? 0 : calculatedValue;
};

/**
 * This method will recursively search for given key value in optimizerConfig object.
 * If it fails to find it in first child then it will move on to 'build_on_scenario' child.
 * At last it will show value present in baseline scenario, if it is not able to find before.
 * @param {object} optimizerConfig - Parent object that contains all scenario wise details
 * @param {object} obj - current object
 * @param {string} key - the key we want to search in whole object.
 * @returns {string} - string value
 */
export const getValueFromOptimizerConfig = (optimizerConfig, obj, key) => {
    if (obj) {
        if (key in obj) {
            return obj[key];
        } else {
            return getValueFromOptimizerConfig(optimizerConfig, optimizerConfig[obj["builds_on_scenario_id"]], key);
        }
    }

    return null;
};

/**
 * This method will convert the single(') quoted part of text in bold
 * @param {string} text - Text which need to be processed containing (')s
 * @returns {string} - Processed text
 */
export const processTextToBold = (text) => {
    // Regular expression to find quoted parts
    const regex = /('.*?')/g;
    let match;
    let lastIndex = 0;
    const result = [];

    // Find all matches for quoted text
    while ((match = regex.exec(text)) !== null) {
        // Push the text before the current match
        if (match.index > lastIndex) {
            result.push(text.substring(lastIndex, match.index));
        }
        // Push the quoted text wrapped in <strong> tag
        result.push(<strong key={match.index}>{match[0].replaceAll("'", "")}</strong>);
        lastIndex = regex.lastIndex;
    }

    // Push the remaining text after the last match
    if (lastIndex < text.length) {
        result.push(text.substring(lastIndex));
    }

    return result;
};

/**
 * This method will check if provided element is present in viewport.
 * @param {any} element - The element which we want to check
 * @returns {boolean} - Return true/false
 */
export const isInView = (element) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const top = rect.top;
    const bottom = rect.bottom;

    return top >= 0 && bottom <= windowHeight;
};

/**
 * This method will increase/decrease height of textarea based on text content.
 * @param {Element} textArea - Current textarea dom element whose height we want to update.
 */
export const updateTextAreaHeightWithText = (textArea) => {
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + 2 + "px";
};

/**
 * Validate the File types
 * @param {object} files - file props
 * @param {Array} allowedFormats - Formats are allowed for uploading file
 * @returns {boolean} - returns true if files are valid
 */
export const validateFileType = (files, allowedFormats = ["csv", "txt", "xls", "xlsx"]) => {
    let invalidFormat = false;
    for (const file of Array.from(files)) {
        if (!allowedFormats.includes(file.name.split(".").at(-1))) {
            invalidFormat = true;
            break;
        }
    }

    if (invalidFormat) {
        toast.error(
            i18n.t("selected_file_is_not_supported_please_use_one_of_the_allowed_formats", {
                "allowedFormats": allowedFormats.join(", ")
            })
        );

        return false;
    }

    return true;
};

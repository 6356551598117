import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AuthService } from "../services/auth-service/auth-service";
import { getTenantFromUrl } from "../services/tenantConfigService";
import { setLocalStorage } from "../utils/commonHelper";

export const UserContext = createContext();
export const authService = new AuthService();

export const UserContextProvider = withTranslation()(({ children, i18n }) => {
    const [user, setUser] = useState(false);
    const [language, setLanguage] = useState(
        localStorage.getItem("language") ? localStorage.getItem("language") : "en"
    );
    const [privacyMode, setPrivacyMode] = useState("off");
    const [authInitialized, setAuthInitialized] = useState(null);
    const [isAuthed, setIsAuthed] = useState(false);
    const [isProcessingAuth, setIsProcessingAuth] = useState(true);

    const location = useLocation();

    /**
     * Saves selected language
     * @param {string} lang - Selected language code (ex. - en, es)
     */
    const setNewLanguage = useCallback(
        async (lang = "en") => {
            await i18n?.changeLanguage(lang);
            setLocalStorage("language", lang);
        },
        [i18n]
    );

    /**
     * Change tenant
     * @param {string} tenant - Tenant ID
     */
    const changeTenant = (tenant) => {
        let newUrl = `${tenant}/overview`;
        if (!window.location.pathname.includes("/select-tenant")) {
            newUrl = user.replaceTenantInUrl(user.selectedTenant, tenant);
        }
        user.setTenant(tenant);
        window.location.replace(newUrl);
    };

    /**
     * Start login process
     */
    const login = useCallback(() => {
        if (isProcessingAuth && !authInitialized) {
            if (window.location.pathname === "/login") {
                setIsProcessingAuth(false);
            } else {
                setAuthInitialized(true);
            }
        }
    }, [authInitialized, isProcessingAuth]);

    /**
     * Go to select tenant screen
     * @param {string} tenant - Tenant ID
     */
    const goToSelectTenant = (tenant) => {
        // Using window location replace instead of navigate, as we want to refresh the application
        if (!window.location.pathname.includes("/select-tenant")) {
            window.location.replace("/select-tenant");
        }
    };

    /**
     * Check if user is authenticated
     */
    const checkIsAuth = useCallback(() => {
        authService.auth.saveLastUrl();
        authService.auth.isAuthed((isAuthedNew) => {
            if (isAuthedNew) {
                setIsAuthed(true);
                setIsProcessingAuth(false);
            } else {
                login();
            }
        });
    }, [login]);

    /**
     * Get tenant from url and set it in user
     */
    const setTenant = useCallback(
        (newUser) => {
            const tenantId = getTenantFromUrl();
            newUser.setTenant(tenantId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location]
    );

    useEffect(() => {
        setPrivacyMode(localStorage.getItem("privacyMode", "off"));
        setNewLanguage(language);
    }, [setNewLanguage, language]);

    /**
     * Check auth first, if that fails, then try to login
     */
    useEffect(() => {
        /**
         * Login user and check again
         */
        const loginUser = async () => {
            await authService.auth.login();
            checkIsAuth();
        };

        if (authInitialized && !isAuthed) {
            loginUser();
        } else if (!isAuthed) {
            checkIsAuth();
        }
    }, [isAuthed, checkIsAuth, authInitialized]);

    /**
     * Get user from auth service after login is completed
     */
    useEffect(() => {
        /**
         * Gets user from auth service and sets the tenant
         */
        const getUser = async () => {
            const newUser = await authService.auth.getUser(false);
            setTenant(newUser);
            setUser(newUser);
        };

        // Get user after auth process completed
        if (!isProcessingAuth) {
            getUser();
        }
    }, [setTenant, isProcessingAuth, isAuthed]);

    return (
        <UserContext.Provider
            value={{
                authService,
                changeTenant,
                goToSelectTenant,
                language,
                privacyMode,
                setLanguage,
                setPrivacyMode,
                setTenant,
                user
            }}
        >
            {children}
        </UserContext.Provider>
    );
});

UserContextProvider.propTypes = {
    "children": PropTypes.any,
    "i18n": PropTypes.any
};

import config from "../../config";
import { BaseAuth } from "./base-auth";

export class AuthService {
    auth;

    /**
     * Constructor
     */
    constructor() {
        const authType = config.env("REACT_APP_AUTH_TYPE");
        if (authType === "mid2") {
            const { MidAuth } = require("./mid-auth");
            this.auth = new MidAuth();
        } else if (authType === "keycloak") {
            const { KeyCloakAuth } = require("./keycloak-auth");
            this.auth = new KeyCloakAuth();
        } else {
            this.auth = new BaseAuth();
        }
    }
}

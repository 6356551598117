import axios, { isCancel } from "axios";
import { toast } from "react-toastify";
import config from "../config";
import { authService } from "../contexts/UserContext";
import i18n from "../i18n";
import { downloadBlobAsFile, getLocalStorage } from "../utils/commonHelper";
import { getTenantFromUrl } from "./tenantConfigService";

// Generate axios instance
const instance = axios.create({
    "baseURL": config.env("REACT_APP_BASE_URL"),
    "headers": {
        "Access-Control-Allow-Origin": "*"
    }
});

// This controller can be used to cancel all current API calls by calling controller.abort() in unmount
const pageControllers = {};
/**
 * Get controller for current page
 * @param {string} pathname - Path name
 * @returns {AbortController} - Controller
 */
export const getPageController = (pathname) => {
    return pageControllers[pathname];
};

instance.interceptors.request.use(async (req) => {
    // Validate token before call, only where auth is required
    if (!req.tenantNotRequired) {
        const isAuthed = await authService.auth.isAuthedAsync();
        if (!isAuthed) {
            // In case of unauthenticated, refresh screen, so app authenticates again
            window.location.reload();
        }
    }
    const header = getLocalStorage("api-headers", true);

    // Create new controller for page if it does not exist or if it is already aborted
    if (
        !Object.hasOwn(pageControllers, window.location.pathname) ||
        pageControllers[window.location.pathname].aborted
    ) {
        pageControllers[window.location.pathname] = new AbortController();
    }

    // Add signal to all API calls to cancel them, if required
    // It can be overridden by passing custom signal in config
    if (req.config?.signal) {
        req.signal = req.config?.signal;
    } else {
        req.signal = pageControllers[window.location.pathname].signal;
    }

    // If tenant is already there in header, then use that else try getting it from url
    if (req.tenant) {
        req.headers = {
            ...req.headers,
            "Accept-Language": i18n.language,
            "tenant": req.tenant,
            ...header
        };
    } else if (!req.tenantNotRequired) {
        const selectedTenant = req.tenant ? req.tenant : getTenantFromUrl();

        if (selectedTenant) {
            req.headers = {
                ...req.headers,
                "Accept-Language": i18n.language,
                "tenant": selectedTenant,
                ...header
            };
        } else {
            // Don't hit api, if tenant and headers are not available
            return;
        }
    }

    return req;
});

/**
 * Checks if error message is an object, then breaks it by key
 * @param {object} error - Api error
 * @param {string} errorMessage - Partially converted error message
 * @returns {string} - Api error in string format
 */
const convertArrayObjectToString = (error, errorMessage = "") => {
    if (typeof error === "object") {
        Object.keys(error).forEach((key) => {
            if (typeof error[key] === "string") {
                errorMessage += `${key}: ${error[key]}<br><br>`;
            } else {
                errorMessage += `${key}: <br>`;
                errorMessage = `${convertArrayObjectToString(error[key], errorMessage)}`;
            }
        });
    } else {
        errorMessage += error;
    }

    return errorMessage;
};

instance.interceptors.response.use(
    (res) => {
        // If its a file, pass whole response, so header can be read
        if (res?.config?.config?.isFile || res?.status === 204) {
            return res;
        }

        return res.data;
    },
    async (err) => {
        if (err.response && !isCancel(err)) {
            if (
                err.request.responseType === "blob" &&
                err.response.data instanceof Blob &&
                err.response.data.type &&
                err.response.data.type.toLowerCase().indexOf("json") !== -1
            ) {
                err.response.data = JSON.parse(await err.response.data.text());
            }

            if (err.response.status === 401) {
                // In case of unauthenticated, refresh screen, so app authenticates again
                window.location.reload();
            } else if (err.response.status === 403 && !err.response?.config?.url.includes("/tenants")) {
                // Don't show toast if tenant not found in tenants api
                // Navigate to forbidden screen if we get 403 from api
                // window.location.replace("/forbidden");
                const errorMessage = err.response.data.message ? err.response.data.message : i18n.t("forbidden");
                toast.error(errorMessage, {
                    "autoClose": 5000,
                    "closeOnClick": true,
                    "draggable": true,
                    "hideProgressBar": false,
                    "pauseOnHover": true,
                    "progress": undefined
                });
            } else if (
                err.response.status === 400 &&
                !err.response.config.handlerEnabled &&
                !err.response.config.disableResponseConversion
            ) {
                err.response.data.message = convertArrayObjectToString(
                    JSON.parse(JSON.stringify(err.response.data.message))
                ).replaceAll("<br>", "\n");
            } else if (err.response.config.handlerEnabled) {
                err.response.data.message = convertArrayObjectToString(
                    JSON.parse(JSON.stringify(err.response.data.message))
                );
                const errorMessage = err.response.data.message
                    ? err.response.data.message
                    : i18n.t("error_messages.error_while_receiving_data");
                toast.error(<div dangerouslySetInnerHTML={{ "__html": errorMessage }}></div>, {
                    "autoClose": 5000,
                    "closeOnClick": true,
                    "draggable": true,
                    "hideProgressBar": false,
                    "pauseOnHover": true,
                    "progress": undefined
                });
            }
        }

        // Return error, only if API calls were not cancelled deliberately
        if (!isCancel(err)) {
            throw err;
        }
    }
);

/**
 * Get response in chunks as API takes time to respond
 * @param {string} url - API URL
 * @param {string} method - GET/POST/PUT/DELETE
 * @param {object} data - Post data
 * @param {object} config - API Config
 * @param {boolean} isFile - If file being uploaded
 * @returns {Promise} - API Promise
 */
export const streamApi = (url, method, data, config = {}, isFile = false) => {
    let response;

    const request = {
        "config": { ...config, isFile },
        "data": data,
        "method": method,
        /**
         * On Download Progress
         * @param {ProgressEvent} progressEvent - Progress Event
         */
        "onDownloadProgress": (progressEvent) => {
            let dataChunk;

            if (progressEvent?.event?.currentTarget?.response) {
                if (!isFile) {
                    dataChunk = progressEvent.event.currentTarget.response.replaceAll("#", "");
                } else {
                    dataChunk = progressEvent.event.currentTarget.response;
                }
            }

            if (dataChunk && dataChunk !== "") {
                if (!isFile) {
                    if (dataChunk.endsWith("}") || dataChunk.endsWith("]")) {
                        response = dataChunk;
                    }
                } else {
                    response = dataChunk;
                }
            }
        },
        "url": url
    };

    if (isFile) {
        request["responseType"] = "blob";
    }

    if (config.params) {
        request.params = config.params;
    }

    return instance(request)
        .then((resp) => {
            if (!isFile) {
                try {
                    if (response) {
                        response = JSON.parse(response.replaceAll("#", ""))[0];
                    } else {
                        if (typeof resp === "string") {
                            response = JSON.parse(resp.replaceAll("#", ""))[0];
                        } else {
                            response = resp;
                        }
                    }
                } catch (e) {
                    // Catch random failures because of partial json
                    // eslint-disable-next-line no-console
                    console.error("Error while parsing json - ", e);
                    response = { "message": "Unable to handle response" };
                }
            }

            if ((response && response.success) || (isFile && (resp || response))) {
                // If stream request completes quickly, then we get response in resp object
                if (!response) {
                    response = resp.data;
                }

                if (isFile && resp.status === 200) {
                    // Download file
                    response.text().then((responseText) => {
                        // Remove all extra spaces sent for streaming
                        let symbolCount = 0;
                        while (responseText.startsWith("#")) {
                            responseText = responseText.slice(1);
                            symbolCount++;
                        }
                        const finalResponse = response.slice(symbolCount);
                        downloadBlobAsFile(finalResponse, resp.headers["file-name"]);
                    });
                } else if (isFile && resp.status === 204) {
                    return Promise.reject({
                        "response": {
                            "data": {
                                "surveyGenerated": false
                            }
                        }
                    });
                }

                return response;
            } else {
                return Promise.reject(response);
            }
        })
        .catch((e) => {
            // Return error, only if API calls were not cancelled deliberately
            if (!isCancel(e)) {
                if (e) {
                    return Promise.reject(e);
                }
            }
        });
};

export default instance;

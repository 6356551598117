import axios from "./axios";
import { serviceUrls } from "./serviceUrls";

/**
 * Fetch Translations from API
 * @param {string} language - Language
 * @param {string} namespace - Namespace
 * @param {object} apiConfig - API Configuration
 * @returns {object} - Translations
 */
export const getTranslations = (
    language,
    namespace,
    apiConfig = { "handlerEnabled": true, "tenantNotRequired": true }
) => {
    return axios.get(serviceUrls("translations", { language, namespace }), apiConfig);
};
